<template>
  <div>
    <div class="selectors-3pv is-centered">
      <div class="selectors-3pv__container">
        <div class="selectors-3pv__header-container" :style="{ background: custom }">
          <span>Torneos</span>
        </div>
        <div class="selectors-3pv__selectors-container">
          <div>
            <span class="title-selectors">TORNEO</span>
            <BSelect :expanded="true" placeholder="Selecciona uno" :value="selectedTournament" @input="setCurrentRisk">
              <option v-for="option in tournaments" :key="option.id" :value="option.id">
                {{ option.name }}
              </option>
            </BSelect>
          </div>
          <div>
            <span class="title-selectors">TEMPORADA</span>
            <BSelect :expanded="true" :value="selectedSeason" @input="setCurrentSeason">
              <option v-for="option in seasonsView" :key="option.id" :value="option.id">
                {{ option.name }}
              </option>
            </BSelect>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BSelect } from 'buefy/dist/esm/select';

export default {
  name: 'Selectors3Pv',
  components: {
    BSelect,
  },
  props: {
    custom: {
      type: String,
      default: '#242424',
    },
  },
  data() {
    return {
      url: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      retries: 3,
      tournaments: [],
      seasons: {},
      seasonsView: [],
      selectedSeason: 1,
      selectedTournament: 1,
      groupedSeasons: null,
      fullTournamentSelected: {},
    };
  },
  mounted() {
    this.getTournaments();
  },
  watch: {
    selectedSeason(newValue) {
      if (newValue) {
        this.$emit('seasons-selected', this.groupedSeasons[newValue]);
      }
    },
  },
  methods: {
    async getTournaments() {
      try {
        const { data: tournamentsAndSeasons } = await axios
          .get(this.url)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getTournaments();
            }
            return e;
          });
        this.createGroupedSeasons(tournamentsAndSeasons);
        tournamentsAndSeasons.forEach(tournamentAndSeasons => {
          const tournamentView = tournamentAndSeasons.tournament.id;
          this.tournaments.push({
            name: tournamentAndSeasons.tournament.name,
            id: tournamentAndSeasons.tournament.id,
            logo: tournamentAndSeasons.tournament.logo,
          });
          tournamentAndSeasons.seasons.forEach(season => {
            if (season.type !== 1) {
              if (this.seasons[tournamentView]) {
                this.seasons[tournamentView].push({ name: season.name, id: season.id });
              } else {
                this.seasons[tournamentView] = [];
                this.seasons[tournamentView].push({ name: season.name, id: season.id });
              }
            }
          });
        });
        this.setFullTournament(this.selectedTournament);
        this.seasonsView = this.seasons[this.selectedTournament];
        this.selectedSeason = this.seasonsView[this.seasonsView.length - 1].id;
        this.$emit('season-selected', this.selectedSeason);
        this.$emit('tournament-selected', this.fullTournamentSelected);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    setCurrentRisk(tournamentId) {
      this.setFullTournament(tournamentId);
      this.selectedTournament = tournamentId;
      this.seasonsView = this.seasons[tournamentId];
      this.selectedSeason = this.seasonsView[this.seasonsView.length - 1].id;
      this.$emit('season-selected', this.selectedSeason);
      this.$emit('tournament-selected', this.fullTournamentSelected);
    },
    setCurrentSeason($val) {
      this.selectedSeason = $val;
      this.$emit('season-selected', $val);
      this.$emit('tournament-selected', this.fullTournamentSelected);
    },
    setFullTournament(tournamentId) {
      this.fullTournamentSelected = this.tournaments.find(tournament => tournament.id === tournamentId);
    },
    /**
     * @author Alejandro Martinez
     * @description Groups all seasons by it's regular season id
     * @param {Array<{
     *     tournament: {id: number, logo: string, name: string},
     *     seasons: Array<{
     *       id: number,
     *       type: number,
     *       related_seasons: Array<{id: number, type: number}>
     *     }>
     *   }>} tournamentsAndSeasons - All tournaments with it's seasons included:
     *   type: refers to the season type, values can be 0 for regular season or 1 for playoffs
     */
    createGroupedSeasons(tournamentsAndSeasons) {
      this.groupedSeasons = tournamentsAndSeasons.reduce((groupedSeasons, tournamentAndSeasons) => {
        const regularSeason = tournamentAndSeasons.seasons[0];
        groupedSeasons[regularSeason.id] = [
          { id: regularSeason.id, type: regularSeason.type, name: regularSeason.name },
        ];
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          groupedSeasons[regularSeason.id].push(
            ...regularSeason.related_seasons.map(relatedSeason => ({
              id: relatedSeason.id,
              type: relatedSeason.type,
            })),
          );
        }
        return groupedSeasons;
      }, {});
    },
  },
};
</script>

<style lang="scss">
.title-selectors {
  font-family: 'Circular-Std';
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #484848;
}
.selectors-3pv {
  display: flex;
  justify-content: center;
  transition: all 0.3s ease 0s;
  perspective: 1260px;
  &__container {
    width: 1268px;
    height: 150px;
    table-layout: fixed;
  }
  &__header-container {
    width: 100%;
    background: #242424;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto;
    span {
      color: white;
      height: 40px;
      line-height: 40px;
      font-family: 'Circular-Std-Medium';
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.52px;
      text-align: center;
      color: #ffffff;
    }
  }
  &__selectors-container {
    height: 70px;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    text-align: left;
    width: 100%;
    & > div:first-child {
      width: 240px;
      margin-right: 30px;
    }
    & > div:last-child {
      width: 240px;
      margin-left: 30px;
    }
    @media screen and (max-width: 1080px) {
      margin-top: 18px;
    }
    @media screen and (max-width: 767px) {
      & > div:first-child {
        width: 180px;
        margin-right: 20px;
      }
      & > div:last-child {
        width: 180px;
        margin-left: 20px;
      }
    }
    @media screen and (max-width: 500px) {
      & > div:first-child {
        width: 160px;
        margin-right: 4px;
      }
      & > div:last-child {
        width: 160px;
        margin-left: 4px;
      }
    }
    @media screen and (max-width: 340px) {
      & > div:first-child {
        width: 132px;
        margin-right: 4px;
      }
      & > div:last-child {
        width: 132px;
        margin-left: 4px;
      }
    }
  }
}
</style>
